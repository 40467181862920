
import { defineComponent, onMounted } from "vue";
import NavigationBar from "@/components/NavigationBar.vue";
import { isTouchDevice } from "@/utils";

export default defineComponent({
  name: "app",
  components: { NavigationBar },
  setup() {
    onMounted(() => {
      console.log("mounted in the composition api!");
    });
  },
  mounted() {
    const html = document.querySelector("html");
    html?.classList.add(isTouchDevice() ? "touch" : "noTouch");

    if (!isTouchDevice()) {
      window.addEventListener("mousemove", e =>
        this.$eventBus.emit("windowMouseMove", e)
      );
    }

    window.addEventListener("scroll", e =>
      this.$eventBus.emit("windowScroll", e)
    );

    window.addEventListener("resize", e =>
      this.$eventBus.emit("windowResize", e)
    );
  }
});
