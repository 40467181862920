
import { defineComponent, ref } from "vue";
import { isMobile } from "@/utils";

export default defineComponent({
  name: "TheNavigation",
  setup() {
    const mainNavigationContainer = ref<HTMLElement | null>(null);

    function expandMenu() {
      mainNavigationContainer.value?.classList.add("is-open");
    }

    function closeMenu() {
      mainNavigationContainer.value?.classList.remove("is-open");
    }

    function closeIfMobile() {
      if (isMobile()) closeMenu();
    }

    return { mainNavigationContainer, expandMenu, closeMenu, closeIfMobile };
  },
});
